<template>
	<div class="layout-invoice-page">
        <Loader v-model="loading" />
        <Button type="button" label="Print" icon="pi pi-print" @click="printClick" style="display:block;margin-bottom: 20px; margin-left: 6px;" class="p-button-outlined invoice-button"></Button>
        <div class="grid" v-if="invoice.id">
            <div class="col">
                <div class="card" >
                    <div id="invoice-content">
                        <div class="invoice">
                            <div class="p-grid">
                                <div class="p-col-3 invoice-header">

                                 </div>
                                 <div class="p-col-3 invoice-header">
                                    <p>Expedido en {{branch.address}}</p>
                                 </div>
                                <div class="p-col-6 invoice-header">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-col-4">Certificado
                                        </div>
                                        <div class="p-col-8">
                                        </div>
                                        <div class="p-col-4">Certificado SAT
                                        </div>
                                        <div class="p-col-8">
                                        </div>
                                        <div class="p-col-4">Fecha Emision
                                        </div>
                                        <div class="p-col-8">{{invoice.date}}
                                        </div>
                                        <div class="p-col-4">Fecha Certificacion
                                        </div>
                                        <div class="p-col-8">{{invoice.certification_date}}
                                        </div>
                                         <div class="p-col-4">Folio Fiscal
                                        </div>
                                        <div class="p-col-8">{{invoice.uuid}}
                                        </div>
                                    </div>
                                </div>
                                 <div class="p-col-6">
                                    <Fieldset legend="Emisor">
                                        <div class="p-fluid p-formgrid p-grid invoice-emisor">
                                             <div class="p-col-6"><label>Nombre</label></div>
                                            <div class="p-col-6"><label>{{branch.name}}</label></div>
                                            <div class="p-col-6"><label>Direccion</label></div>
                                            <div class="p-col-6"><label>{{branch.address}}</label></div>
                                            <div class="p-col-6"><label>Colonia</label></div>
                                            <div class="p-col-6"><label>{{branch.suburb}}</label></div>
                                            <div class="p-col-6"><label>Municipio</label></div>
                                            <div class="p-col-6"><label>{{branch.city}}</label></div>
                                            <div class="p-col-6"><label>Estado</label></div>
                                            <div class="p-col-6"><label>{{branch.state}}</label></div>
                                            <div class="p-col-6"><label>RFC</label></div>
                                            <div class="p-col-6"><label>{{branch.tax_number}}</label></div>
                                            <div class="p-col-6"><label>C.P.</label></div>
                                            <div class="p-col-6"><label>{{branch.zip_code}}</label></div>
                                            <div class="p-col-6"><label>Telefono</label></div>
                                            <div class="p-col-6"><label>{{branch.phone}}</label></div>
                                        </div>
                                    </Fieldset>
                                </div>
                                <div class="p-col-6">
                                    <Fieldset legend="Receptor">
                                        <div class="p-fluid p-formgrid p-grid invoice-receptor">
                                            <div class="p-col-6"><label>Nombre</label></div>
                                            <div class="p-col-6"><label>{{invoice.receiver_name }}</label></div>
                                            <div class="p-col-6"><label>Direccion</label></div>
                                            <div class="p-col-6"><label>{{invoice.receiver_tax_residence}}</label></div>
                                            <div class="p-col-6"><label>C.P.</label></div>
                                            <div class="p-col-6"><label>{{invoice.receiver_postal_code}}</label></div>
                                            <div class="p-col-6"><label>Uso CFDI</label></div>
                                            <div class="p-col-6"><label>{{invoice.receiver_invoice_use}}</label></div>
                                            <div class="p-col-6"><label>RFC</label></div>
                                            <div class="p-col-6"><label>{{invoice.receiver_rfc}}</label></div>
                                        </div>
                                    </Fieldset>
                                </div>
                                <div class="p-col-12">
                                    <div class="invoice-items">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>Descripcion</th>
                                                <th>Cantidad</th>
                                                <th>P.U.</th>
                                                <th>Importe</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="invoice_concepts" v-for="concept in invoice.invoice_concepts" :key="concept.id">
                                                    <td>{{concept.description}}</td>
                                                    <td>{{concept.quantity}}</td>
                                                    <td>{{concept.unit_value.toLocaleString('es-MX', {
                                                        style: 'currency',
                                                        currency: 'MXN',
                                                        })}}</td>
                                                    <td>{{concept.amount.toLocaleString('es-MX', {
                                                        style: 'currency',
                                                        currency: 'MXN',
                                                        })}}</td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="p-col-12 amount-word">
                                    <p>Importe con letra</p>
                                    <p>(Setecientos Noventa y Siete Mil Doscientos Diez Pesos 00/100 M.N.)</p>
                                </div>
                                <div class="p-col-12">
                                    <div class="invoice-summary">
                                        <table>
                                            <tbody>
                                            <tr>
                                                <td>SubTotal</td>
                                                <td>{{invoice.subtotal.toLocaleString('es-MX', {
                                                        style: 'currency',
                                                        currency: 'MXN',
                                                        })}}</td>
                                            </tr>
                                            <tr>
                                                <td>IVA</td>
                                                <td>{{(0).toLocaleString('es-MX', {
                                                        style: 'currency',
                                                        currency: 'MXN',
                                                        })}}</td>
                                            </tr>
                                            <tr>
                                                <td>Total</td>
                                                <td>{{invoice.total.toLocaleString('es-MX', {
                                                        style: 'currency',
                                                        currency: 'MXN',
                                                        })}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="p-col-6 invoice-header">
                                    <div class="p-fluid p-formgrid p-grid">
                                        <div class="p-col-4">Metodo de Pago</div>
                                        <div class="p-col-8">{{metodo_pago}}</div>
                                        <div class="p-col-4">Forma de Pago</div>
                                        <div class="p-col-8">{{forma_pago}}</div>
                                        <div class="p-col-4">Moneda</div>
                                        <div class="p-col-8">{{invoice.currency}}</div>
                                        <div class="p-col-4">T.C.</div>
                                        <div class="p-col-8">
                                            {{invoice.exchange_rate.toLocaleString('es-MX', {
                                                        style: 'currency',
                                                        currency: 'MXN',
                                                        })}}
                                        </div>
                                    </div>
                                </div>
                                <div class="p-col-6 invoice-header">
                                    
                                </div>
                                <div class="p-col-12 invoice-header">
                                    <Card>
                                        <template #title>"Este documento es una representación impresa de un CFDI"</template>
                                        <template #content>    
                                            <div class="p-fluid p-formgrid p-grid">
                                            <div class="p-col-4">Metodo de Pago</div>
                                            <div class="p-col-8">{{metodo_pago}}</div>
                                        </div>
                                        </template>
                                         
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Invoice } from '../../../models/cfdi40/Invoice';
import Loader from '../../../components/general/Loader.vue';
import { ErrorToast, fillObject, satCatalogo } from '../../../utilities/General';
import { Branch } from '../../../models/general/Branch';
import { Customer } from '../../../models/comercial/Customer';
export default {
    props: {
     id: null,
    },
    components: {
        Loader
        },
	data() {
		return {
            branch:  new Branch(),
            invoice: new Invoice(), 
            customer: new Customer(),
            loading: false,
            c_MetodoPago: [],
            c_FormaPago: [],
		}
	},
    computed: {
        metodo_pago() {
            if (this.invoice.payment_method && this.c_MetodoPago.length > 0) {
                let metodo = this.c_MetodoPago.find(x => x.c_MetodoPago == this.invoice.payment_method);
                return metodo.c_MetodoPago + " - " + metodo.Descripcion.toUpperCase();
            }else {
                return ""
            }
        },
        forma_pago() {
            if (this.invoice.payment_form && this.c_FormaPago.length > 0) {
                let forma = this.c_FormaPago.find(x => x.c_FormaPago == this.invoice.payment_form);
                return forma.c_FormaPago + " - " + forma.Descripcion.toUpperCase();
            }else {
                return ""
            }
        }
    },
	methods: {
		printClick() {
			window.print();
		}
	},
    async mounted() {
        this.invoice.id = parseInt(this.id);
        try {
            //Aqui traemos los datos
            let invoice = await this.invoice.retrieve();
            this.invoice = fillObject(this.invoice, invoice);
            this.invoice.invoice_concepts = await this.invoice.concepts();
            //Datos del receptor
            this.branch.id = this.invoice.id_branch;
            let branch = await this.branch.retrieve();
            this.branch = fillObject(this.branch, branch);
            this.c_MetodoPago = await satCatalogo(3);
            this.c_FormaPago = await satCatalogo(6);
        } catch (error) {
            this.$toast.add(new ErrorToast(error));
        }
    }
}
</script>

<style scoped>
    .p-fieldset-legend-text {
        color: black;
    }
    @media print {
        .amount-word {
            font-size: x-small;
            color: black;
        }
        .invoice-summary {
            font-size: x-small;
            color: black;
        }

        .p-fieldset-legend-text {
            color: black;
        }
        .invoice-header {
            font-size: x-small;
            color: black;
        }
        .invoice-emisor {
            font-size: x-small;
            color: black;
        }
        .invoice-receptor {
            font-size: x-small;
            color: black;
        }
        .invoice_concepts {
            font-size: x-small;
            color: black;
        }
    }
    
</style>
